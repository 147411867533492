"use client";
import { useState, useEffect, useContext } from "react";
import { useRouter, usePathname } from "next/navigation";
import { Tabs, Tab } from "@nextui-org/react";
import { AuthContext, AuthContextType } from "@/providers/AuthProvider";
import HoverBorderNormal from "@/components/ui/HovBtn2";
import { BellRing, LogOut } from "lucide-react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "@/firebase/client";
import { format } from "date-fns";
// import LoginModal from "./auth/LoginModal";
// import ForgotPassModal from "./auth/ForgotPassModal";
// import { GlobalContext, GlobalType } from "@/providers/GlobalProvider";
// import HoverBorderGradient from "@/components/ui/HoverBtn";
// import Link from "next/link";
// const links = [
//   { url: "/", title: "Home" },
//   { url: "/org", title: "Org Dashboard" },
//   { url: "/investorReport", title: "Investment Tracker" },
//   { url: "/admin", title: "Admin" },
// ];

function NavBar() {
  const { signOutUser, currentUser, navLinks } = useContext(
    AuthContext
  ) as AuthContextType;
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [sheetOpen, setSheetOpen] = useState<boolean>(false);
  const [userNotifs, setUserNotifs] = useState<any>([]);

  const router = useRouter();
  const pathname = usePathname();
  const [navKey, setNavKey] = useState<string>("/");

  const navRoute = (link: any) => {
    setNavKey(link);
    router.push(link);
  };

  useEffect(() => {
    if (pathname) {
      setNavKey(`/${pathname.split("/")[1]}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (currentUser?.id && currentUser?.role === "L-USER") {
      const orgDocsQ = query(
        collection(db, "userNotifications", currentUser.id, "notifications"),
        where("userLinkId", "==", currentUser.id),
        orderBy("createdAt", "desc")
      );

      const unsubscribe = onSnapshot(
        orgDocsQ,
        (snapshot) => {
          setUserNotifs(
            snapshot.docs.map((doc) => {
              return doc.data();
            })
          );
        },
        (err) => {
          // TODO: handle errors;
          console.log("error", err);
        }
      );

      return () => unsubscribe();
    }
  }, [currentUser]);

  return (
    <div className="overflow-hidden w-full">
      {currentUser?.role === "L-USER" ||
      currentUser === null ||
      currentUser?.role === "Admin" ? (
        <div className="p-2 bg-gray-100">
          <div className="flex justify-between items-center gap-4">
            <div
              className="w-40 font-semibold text-xl cursor-pointer bg-clip-text bg-no-repeat  text-transparent bg-gradient-to-r from-green-600 via-green-500 to-green-600 [text-shadow:0_0_rgba(0,0,0,0.1)] my-3"
              onClick={() => navRoute("/")}
            >
              KnowMyFarm
            </div>

            <div className="max-w-5xl mx-auto bg-transparent hidden md:w-max md:flex gap-20  h-full  items-center justify-center relative z-[1000]">
              {navLinks?.length > 0 ? (
                <div className="flex flex-wrap justify-center  gap-4">
                  <Tabs
                    variant="bordered"
                    aria-label="Tabs variants"
                    radius="full"
                    selectedKey={navKey}
                    onSelectionChange={navRoute}
                    classNames={{
                      tabList: "border",
                    }}
                  >
                    {navLinks.map((link: any) => (
                      <Tab key={link.url} title={link.title}></Tab>
                    ))}
                  </Tabs>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex gap-4 items-center justify-center w-[85px] sm:w-max">
              {currentUser?.role === "L-USER" ? (
                <div className="w-[50px]">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <div className="relative p-3 cursor-pointer border rounded-full">
                        {userNotifs.length ? (
                          <span className="absolute inset-0 text-success-600 font-semibold">
                            {userNotifs.length}
                          </span>
                        ) : (
                          <></>
                        )}
                        <BellRing className="text-primary" />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel className="text-primary">
                        Notifications
                      </DropdownMenuLabel>

                      {userNotifs.length ? (
                        <DropdownMenuRadioGroup value="bottom">
                          {userNotifs.map((un: any) => (
                            // <div className="text-muted-foreground">
                            <div key={un.id}>
                              <DropdownMenuSeparator />
                              <DropdownMenuRadioGroup value="bottom">
                                <div className="text-muted-foreground p-1">
                                  {un.guestName} has entered the farm at{" "}
                                  {format(new Date(un.createdDate), "hh:mm aa")}
                                </div>
                              </DropdownMenuRadioGroup>
                            </div>
                          ))}
                        </DropdownMenuRadioGroup>
                      ) : (
                        <DropdownMenuRadioGroup value="bottom">
                          <DropdownMenuSeparator />
                          <div className="text-muted-foreground">
                            Notifications empty
                          </div>
                        </DropdownMenuRadioGroup>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ) : (
                <></>
              )}
              {pathname === "/reset-password" ||
              pathname === "/sign-In" ||
              pathname === "/forgot-password" ? (
                <></>
              ) : (
                // <div className="h-12  flex items-center">
                //   <HoverBorderNormal
                //     containerClassName="rounded-full h-12 w-24"
                //     highlightColor="#A0DEFF"
                //     onClick={() => {
                //       router.push("/");
                //     }}
                //   >
                //     Home
                //   </HoverBorderNormal>
                // </div>

                <div className="flex justify-end w-40">
                  {!currentUser?.email ? (
                    // <HoverBorderGradient
                    //   containerClassName="rounded-full h-12 w-24"
                    //   // borderClassName="bg-[radial-gradient(var(--sky-500)_40%,transparent_60%)]"
                    //   as="button"
                    //   className="flex items-center space-x-2"
                    //   onClick={() => {
                    //     // router.push("/login")
                    //     onOpen();
                    //   }}
                    // >
                    //   Sign In
                    // </HoverBorderGradient>
                    <HoverBorderNormal
                      containerClassName="rounded-full h-12 w-24"
                      highlightColor="#cdffcd"
                      onClick={() => router.push("/sign-In")}
                    >
                      Sign In
                    </HoverBorderNormal>
                  ) : (
                    <div className="hidden sm:!flex">
                      <HoverBorderNormal
                        containerClassName="rounded-full h-12 w-24"
                        // className="rounded-lg text-sm bg-black text-white flex items-center space-x-2 border-[1px] border-slate-500 px-2 py-1"
                        // containerClassName="h-10 w-10"
                        // borderRadius="1.75rem"
                        highlightColor="#FFF9D0"
                        onClick={() => setLogoutOpen(true)}
                      >
                        Log out
                      </HoverBorderNormal>
                    </div>
                  )}
                </div>
              )}
            </div>
            {currentUser?.email && (
              <div className="md:hidden">
                <button
                  className="w-10 h-6 flex flex-col justify-between items-end z-[2000] relative my-3"
                  onClick={() => setSheetOpen(true)}
                >
                  <div className="w-10 h-1 bg-green-500 rounded"></div>
                  <div className="w-10 h-1 bg-green-500 rounded"></div>
                  <div className="w-10 h-1 bg-green-500 rounded"></div>
                </button>
              </div>
            )}
          </div>
          <Sheet
            open={sheetOpen}
            onOpenChange={(val: boolean) => {
              // if (!val) {
              //   setSheetData(undefined);
              // }
              setSheetOpen(val);
            }}
          >
            <SheetContent className="z-[1100] border border-t-1 overflow-y-scroll overflow-x-hidden max-w-[250px]">
              <div className="flex flex-col text-lg gap-4 py-10">
                {navLinks?.map((link: any) => (
                  <div
                    className="w-full border rounded-md p-2 flex gap-2 items-center cursor-pointer"
                    key={link.url}
                    onClick={() => {
                      router.push(link?.url);
                      setSheetOpen(false);
                    }}
                  >
                    {link.icon}
                    <div>{link?.title}</div>
                  </div>
                  // <Link key={idx} href={link?.url}>
                  //   {link?.title}
                  // </Link>
                ))}
                <div
                  className="w-full border rounded-md p-2 flex gap-2 items-center"
                  onClick={() => {
                    router.push("/");
                    setSheetOpen(false);
                    signOutUser();
                  }}
                >
                  <LogOut className="h-6 w-6 text-success" />
                  <div>Log Out</div>
                </div>
              </div>
            </SheetContent>
          </Sheet>
          <Modal
            backdrop="opaque"
            isOpen={logoutOpen}
            onClose={() => setLogoutOpen(false)}
            radius="lg"
            classNames={{
              header: "border border-b-[1px]",
            }}
          >
            <ModalContent>
              <ModalHeader className="flex flex-col gap-1">
                <div className="text-2xl font-bold">Log out?</div>
              </ModalHeader>
              <ModalBody>Are you sure you want to log out?</ModalBody>
              <ModalFooter>
                <Button
                  className="shadow-lg text-foreground bg-default-100/80 border hover:bg-transparent"
                  onClick={() => {
                    setLogoutOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="shadow-lg bg-warning-500 hover:bg-warning-400"
                  onClick={() => {
                    signOutUser();
                    setLogoutOpen(false);
                  }}
                >
                  Log out
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* <VerifiModal verify={verify} /> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NavBar;
