"use client";

import {
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { createContext, Dispatch, ReactNode, useEffect, useState } from "react";
import { auth } from "@/firebase/client";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter, usePathname } from "next/navigation";
import { LayoutDashboard, House, NotepadTextDashed } from "lucide-react";

export function getAuthToken() {
  return Cookies.get("firebaseIdToken");
}

export function setAuthToken(token: string) {
  return Cookies.set("firebaseIdToken", token, { secure: true });
}

export function removeAuthToken() {
  return Cookies.remove("firebaseIdToken");
}

export type AuthContextType = {
  currentUser: any;
  login: (email: string, password: string, cb: any) => void;
  signOutUser: () => void;
  error: string | null;
  loadingSI: boolean;
  navLinks: any;
  role: string;
  permissions: string[];
  tabKey: string;
  setTabKey: Dispatch<any>;
};

const AdminList = [
  "2bKmKkKI99OuDoUBZ1lQeSD2dNv2",
  "7q9pGXIyenRNaXvC70KAIx8hciK2",
];

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loadingSI, setLoadingSI] = useState<boolean>(false);
  const [navLinks, setNavLinks] = useState<any>([]);
  const [role, setRole] = useState<any>("free");
  const [permissions, setPermissions] = useState<any>([]);
  const [tabKey, setTabKey] = useState<any>("overview");

  const login = (email: string, password: string, cb: any) => {
    setLoadingSI(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((_res) => {
        setLoadingSI(false);
        cb();
        setError(null);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingSI(false);
        setError("Invalid email or password! Please try again!");
      });
  };

  const signOutUser = () => {
    signOut(auth).then((_res) => {
      setCurrentUser(null);
    });
  };

  useEffect(() => {
    if (currentUser === null) {
      setNavLinks([]);
      if (
        !(
          pathname === "/reset-password" ||
          pathname === "/sign-In" ||
          pathname === "/forgot-password"
        )
      ) {
        router.push("/");
      }
    }
  }, [currentUser, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (auth) {
      onAuthStateChanged(auth, async (user) => {
        if (!user) {
          removeAuthToken();
        }
        if (user?.uid) {
          const token = await user?.getIdToken();
          const tokenVal = await user?.getIdTokenResult();
          if (tokenVal?.claims?.role) {
            setRole(tokenVal.claims.role);
          }
          if (tokenVal?.claims?.permissions) {
            setPermissions(tokenVal.claims.permissions);
          }
          setAuthToken(token);
          axios
            .get(`/api/user/${user?.uid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(function (res) {
              if (res?.data?.data) {
                if (res.data.data?.type === "SUB-ORG") {
                  setCurrentUser({
                    ...res.data.data,
                    id: res.data.data.subOrgId,
                  });
                } else {
                  setCurrentUser(res.data.data);
                }

                if (AdminList.includes(user?.uid)) {
                  setNavLinks([
                    {
                      url: "/",
                      title: "Home",
                      icon: <House className="h-6 w-6 text-success" />,
                    },
                    {
                      url: "/admin",
                      title: "Admin",
                      icon: (
                        <LayoutDashboard className="h-6 w-6 text-success" />
                      ),
                    },
                  ]);
                } else if (
                  res.data.data?.type === "ORG" ||
                  res.data.data?.type === "SUB-ORG"
                ) {
                  setNavLinks([
                    {
                      url: "/",
                      title: "Home",
                      icon: <House className="h-6 w-6 text-success" />,
                    },
                    {
                      url: "/org",
                      title: "Dashboard",
                      icon: (
                        <LayoutDashboard className="h-6 w-6 text-success" />
                      ),
                    },
                    {
                      url: "/sales",
                      title: "Sales",
                      icon: (
                        <NotepadTextDashed className="h-6 w-6 text-success" />
                      ),
                    },
                  ]);
                  setTimeout(() => {
                    router.push("/org");
                  }, 200);
                } else if (res?.data?.data?.role === "L-USER") {
                  setNavLinks([
                    {
                      url: "/",
                      title: "Home",
                      icon: <House className="h-6 w-6 text-success" />,
                    },
                    {
                      url: "/investorReport",
                      title: "Dashboard",
                      icon: (
                        <LayoutDashboard className="h-6 w-6 text-success" />
                      ),
                    },
                    // { url: "/admin", title: "Admin" },
                  ]);

                  setTimeout(() => {
                    router.push("/investorReport");
                  }, 200);
                }
              } else {
                setNavLinks([]);
                if (
                  !(
                    pathname === "/reset-password" ||
                    pathname === "/sign-In" ||
                    pathname === "/forgot-password"
                  )
                ) {
                  router.push("/");
                }
              }
            })
            .catch(function (error) {
              setCurrentUser(null);
              console.log("err", error);
            });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        login,
        signOutUser,
        currentUser,
        error,
        loadingSI,
        navLinks,
        role,
        permissions,
        tabKey,
        setTabKey,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
